var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "sidebar-nav" },
    [
      _c(
        "VuePerfectScrollbar",
        {
          staticClass: "scroll-area",
          attrs: { settings: _vm.psSettings },
          on: { "ps-scroll-y": _vm.scrollHandle }
        },
        [
          _c(
            "ul",
            { staticClass: "nav" },
            [
              _vm._l(_vm.navItems, function(item, index) {
                return [
                  item.title
                    ? [
                        _c("SidebarNavTitle", {
                          key: index,
                          attrs: {
                            name: item.name,
                            classes: item.class,
                            wrapper: item.wrapper
                          }
                        })
                      ]
                    : item.divider
                    ? [
                        _c("SidebarNavDivider", {
                          key: index,
                          attrs: { classes: item.class }
                        })
                      ]
                    : item.label
                    ? [
                        _c("SidebarNavLabel", {
                          key: index,
                          attrs: {
                            name: item.name,
                            url: item.url,
                            icon: item.icon,
                            label: item.label,
                            classes: item.class
                          }
                        })
                      ]
                    : [
                        item.children
                          ? [
                              _c(
                                "SidebarNavDropdown",
                                {
                                  key: index,
                                  attrs: {
                                    name: item.name,
                                    url: item.url,
                                    icon: item.icon
                                  }
                                },
                                [
                                  _vm._l(item.children, function(
                                    childL1,
                                    index1
                                  ) {
                                    return [
                                      childL1.children
                                        ? [
                                            _c(
                                              "SidebarNavDropdown",
                                              {
                                                key: index1,
                                                attrs: {
                                                  name: childL1.name,
                                                  url: childL1.url,
                                                  icon: childL1.icon
                                                }
                                              },
                                              _vm._l(childL1.children, function(
                                                childL2,
                                                index2
                                              ) {
                                                return _c(
                                                  "li",
                                                  {
                                                    key: index2,
                                                    staticClass: "nav-item"
                                                  },
                                                  [
                                                    _c("SidebarNavLink", {
                                                      attrs: {
                                                        name: childL2.name,
                                                        url: childL2.url,
                                                        icon: childL2.icon,
                                                        badge: childL2.badge,
                                                        variant:
                                                          childL2.variant,
                                                        attributes:
                                                          childL2.attributes
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        : [
                                            _c(
                                              "SidebarNavItem",
                                              {
                                                key: index1,
                                                attrs: { classes: item.class }
                                              },
                                              [
                                                _c("SidebarNavLink", {
                                                  attrs: {
                                                    name: childL1.name,
                                                    url: childL1.url,
                                                    icon: childL1.icon,
                                                    badge: childL1.badge,
                                                    variant: childL1.variant,
                                                    attributes:
                                                      childL1.attributes
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                    ]
                                  })
                                ],
                                2
                              )
                            ]
                          : [
                              _c(
                                "SidebarNavItem",
                                { key: index, attrs: { classes: item.class } },
                                [
                                  _c("SidebarNavLink", {
                                    attrs: {
                                      name: item.name,
                                      url: item.url,
                                      icon: item.icon,
                                      badge: item.badge,
                                      variant: item.variant,
                                      attributes: item.attributes
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.loadGuide(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                      ]
                ]
              })
            ],
            2
          ),
          _vm._t("default")
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "guide-modal",
          attrs: {
            "ok-only": "",
            scrollable: "",
            id: "guide-modal",
            size: "xl",
            title: "Vendor Guide (Scroll For More)"
          }
        },
        [
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-001.jpg" }
          }),
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-002.jpg" }
          }),
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-004.jpg" }
          }),
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-003.jpg" }
          }),
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-005.jpg" }
          }),
          _c("img", {
            staticClass: "w-100",
            attrs: { src: "/guides/Vendors guide-page-006.jpg" }
          })
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "vendorPriceBook",
          attrs: { id: "vendorPriceBook", title: "Download Price book." }
        },
        [
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "/templates/Service-Vendor-Import-Template.csv" }
            },
            [
              _vm._v("Download Template"),
              _c("i", { staticClass: "fa fa-file" })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }